
import { defineComponent } from "@vue/runtime-core";
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import ContactDialog from '@/components/Contacts/ContactDialog.vue';
import DataGrid from '../DataGrid.vue';
import Contact from "@/types/contact";

import ContactService from '@/services/ContactService';

const contactService = new ContactService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Email File",
  components: {
    Dialog,
    InputText,
    Textarea,
    Button,
    AutoComplete,
    ContactDialog,
    DataGrid,
  },
  props: {
    show: Boolean,
    fileId: {
      type: String,
      default: "",
    },
    header: {
      type: String,
      default: "Email File",
    },
    fileName: {
      type: String,
      default: "",
    },
    contacts: {
      type: Array,
    },
    custId: {
      type: String,
    }
  },
  emits: ["hide", "onEmail", "onAddressAdded"],
  computed: {
    ...mapGetters({
      getMailControls: "control/getMailControls",
      getClient: "session/getClient",
      getUser: "session/getUser",
    }),
    contactEmails(): Array<string> {
      if (this.contacts) {
        const i = this.contacts
          .flatMap((contact: any) => {
            return contact.contact_email?.split(' ')
          })
          .filter((address: string | undefined) => address && address.toLocaleLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ));
        const s = new Set(i);
        let arr: Array<string> = [];
        s.forEach((i: any) => {
          arr.push(i);
        });
        return arr.sort();
      }

      return [];
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      to: { required },
    };
  },
  created() {
    this.initControls();
    this.mailControls();
  },
  mounted() {
    if(this.getUser?.email) {
      if(!this.cc.includes(this.getUser.email)) {
        this.cc.push(this.getUser.email);
      }
    }
  },
  data() {
    return {
      showContactListDialog: false,
      isNewContact: false,
      contactDialogOpened: false,
      showSaveNewEmail: false,
      newEmailsToSave: [] as Array<string>,
      showEmailModal: false,
      modalFile: this.fileId || "",
      html: false,
      secure: false,
      from: [""],
      to: [] as string[],
      test: "",
      cc: [] as string[],
      bcc: [] as string[],
      subject: undefined,
      body: undefined,
      isSubmitted: false,
      isSendingEmail: false,
      emails: [] as string[],
      contactsInfoToEdit: { contact_id: "" } as Contact | Record<string, unknown>,
      columns: [
        { field: "contact_id", header: "Id", sort: true },
        { field: "contact_name", header: "Name", sort: true },
        { field: "contact_email", header: "Email", sort: true },
      ]
    };
  },
  methods: {
    ...mapActions({
      fetchControls: "control/fetchControl",
      addContact: "customerInquiry/addContact",
      addNotification: "notification/add",
      setCustomerContacItems: 'pos/setCustomerContacItems',
    }),
    onExistingContactSelected(contact: any):void {
      const contactId = contact.data.contact_id;

      this.showContactListDialog = false;
      contactService.getContacts(contactId, this.custId).then((response: any) => {
        if (response && response.length) {

          const oldContact = JSON.parse(JSON.stringify(response[0]));
          this.contactsInfoToEdit = JSON.parse(JSON.stringify(response[0]));

          this.newEmailsToSave.forEach(email => {
            this.addEmailAddressToExistingContact(email);
          });
          this.addContact({
            cust_id: this.custId,
            client: this.getClient,
            portal: contact.contac_portal ? "Y" : "",
            type: contact.contact_type,
            contact: this.contactsInfoToEdit,
            oldContact: oldContact || null,
            contact_id: contactId,
          }).then((response) => {
            this.$emit('onAddressAdded');
          })
        }
      })
    },
    addEmailAddressToExistingContact(email: string) {
      if (email && /^\S+@\S+\.\S+$/.test(email)) {
        const emailItems = (this.contactsInfoToEdit as Contact).email_address_items;

        if (emailItems?.length == null) (this.contactsInfoToEdit as Contact).email_address_items = [];

        (this.contactsInfoToEdit as Contact).email_address_items?.push({
          email_address: email,
        });
      }
    },
    addEmailToContact(isNewContact: boolean) {
      if (isNewContact) {
        this.contactsInfoToEdit = { contact_id: "" };

        this.newEmailsToSave.forEach(email => {
          this.addEmailAddressToExistingContact(email);
        });
        this.isNewContact = true;
        this.showSaveNewEmail = false;
        this.contactDialogOpened = true;
      } else {
        this.isNewContact = false;
        this.showSaveNewEmail = false;
        this.showContactListDialog = true;
      }
    },
    hideDialog() {
      this.showEmailModal = false;
      this.modalFile = "";
      this.html = false;
      this.secure = false;
      this.from = [""];
      this.to = [];
      this.cc = [];
      if(this.getUser?.email) {
        this.cc.push(this.getUser.email);
      }
      this.bcc = [];
      this.subject = undefined;
      this.body = undefined;
      this.mailControls();
    },
    addToEmail(event: any) {
      if(event.key === "Enter") {
        const email = event.target.value.trim() || "";
        if(!this.to.includes(email) && email != "") {
          this.to.push(email);
        }
        event.target.value = "";
      }
    },
    addCCEmail(event: any) {
      if(event.key === "Enter") {
        const email = event.target.value.trim() || "";
        if(!this.cc.includes(email) && email != "") {
          this.cc.push(email);
        }
        event.target.value = "";
      }
    },
    addBCCEmail(event: any) {
      if(event.key === "Enter") {
        const email = event.target.value.trim() || "";
        if(!this.bcc.includes(email) && email != "") {
          this.bcc.push(email);
        }
        event.target.value = "";
      }
    },
    initControls() {
      if (this.getMailControls == null) {
        this.fetchControls({
          id: "MAIL",
          procedure: "MAIL.CONTROL",
          filename: "CONTROL",
          Client: this.getClient,
        }).then(() => {
          this.mailControls();
        });
      }
    },
    search(event: any) {
      const s: string = event.query.toLowerCase() || "";
      this.emails = [
        s,
        ...this.contactEmails.filter((i: any) => i.toLowerCase().includes(s)),
      ];
    },
    mailControls() {
      if (this.getMailControls) {
        let controls = this.getMailControls;
        this.from[0] = controls.m3services_sender_address;
        this.secure = controls.m3services_secure == "Y" ? true : false;
      }
    },
    checkNewEmailAddress(): Array<string> {
      return this.to.filter(email => {
        return !this.contactEmails.includes(email);
      });
    },
    handleCloseContactDialog():void {
      this.contactDialogOpened = false;
    },
    handleContactSave() {
      this.$emit('onAddressAdded');
    },
    async email() {
      this.isSubmitted = true;

      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.isSendingEmail = true;
        // This will show the dialog to save an email if its not registered to a contact
        // if (this.contacts && this.custId) {
        //   this.newEmailsToSave = this.checkNewEmailAddress();
        //   if (this.newEmailsToSave.length) {
        //     this.showSaveNewEmail = true;
        //   }
        // }
        this.$emit("onEmail", {
          From: this.from,
          To: this.to,
          Cc: this.cc,
          Bcc: this.bcc,
          Subject: this.subject,
          Body: this.body,
          Html: this.html,
          Secure: this.secure,
          AttachmentId: this.modalFile,
          Client: this.getClient,
        });
        this.isSendingEmail = false;
        this.mailControls();
        this.hideDialog();
      }
    },
  },
  watch: {
    show(newShow) {
      this.showEmailModal = newShow;
      if (newShow && this.fileId) {
        this.modalFile = this.fileId || "";
      }
    },
  },
});
