
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import Button from "primevue/button";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import FieldServiceOrderService from "@/services/FieldServiceOrderService";
import { FieldServiceOrder } from "@/types/state/fieldServices";

const fieldOrderService= new FieldServiceOrderService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Confirmation",
  components: {
    Button,
    EmailFileDialog,
  },
  data() {
    return {
      custId: '',
      isPDFLoading: false,
      showEmailPDFDialog: false,
      email: "",
      lastOrderId: "",
      lastCustomerContacts: [],
    };
  },
  mounted() {
    this.lastOrderId = this.getLastFieldServiceOrder.record.order_no;
    this.lastCustomerContacts = this.getLastFieldServiceOrder.customer.contact_id_items;
  },
  computed: {
    ...mapState(["session", "pos"]),
    ...mapGetters({
      getLastFieldServiceOrder: "fieldServices/getLastFieldServiceOrder",
      getClient: "session/getClient",
      getPDFs: "fieldServices/getPDFs",
      getLoadingPDFs: "fieldServices/getLoadingPDFs",
    }),
  },
  methods: {
    ...mapActions({
      getFieldServicePDF: "fieldServices/getFieldServicePDF",
      addNotification: "notification/add",
    }),
    handleContinue() {
      this.$router.push('/fieldServices')
    },
    async handleGetOrderPDF(orderId: string) {
      this.isPDFLoading = true;
      const fieldService: FieldServiceOrder = this.getLastFieldServiceOrder.record;
      await this.getFieldServicePDF({
        recordId: fieldService.order_no, 
        reportType: fieldService.order_type, 
        client: this.getClient
      });
      this.isPDFLoading = false;
    },
    orderPDFIcon(id: string) {
      let downloaded: Array<any> = this.getPDFs
      let downloading: boolean = this.getLoadingPDFs.find((i: string) => i === id) !== undefined
      return {
        "pi pi-download col-12":
          downloaded.find((i: any) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner col-12": downloading,
        "pi pi-file-pdf col-12":
          downloaded.find((i: any) => i.id === id) !== undefined &&
          !downloading,
      };
    },
    handleEmailPDF() {
      this.showEmailPDFDialog = true;
    },
    handleCloseEmailDialog() {
      this.showEmailPDFDialog = false;
      this.email = "";
    }, 
    sendEmail(data: any) {
      const { order_no, order_type } = this.getLastFieldServiceOrder.record;
      fieldOrderService.getFieldServicePDF(order_no, this.getClient, data, order_type)
      .then((response) => {
          if(response === 'success') {
            this.addNotification({
              message: `Field Service has been emailed successfully`,
              type: "success",
            });
          }
          else {
            this.addNotification({
              message: response || `Field Service was not sent`,
              type: "error",
            });
          }
        }).catch((error) => {
          this.addNotification({
            message: `Field Service could not be sent: ${error}`,
            type: "error",
          });
        })
    },
  },
});
